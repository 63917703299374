import { BatteryAndChargeStatusIcon } from "src/components/Homes/DeviceDetails/BatteryAndChargeStatusIcon"
import { OnlineStatus } from "src/components/Homes/DeviceDetails/OnlineStatus"
import { ParadiseDevicesFilters } from "src/components/Paradise/ParadiseDevicesFilter"
import { ParadisePager } from "src/components/Paradise/ParadisePager"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import {
  Ellipsis,
  IdWrapper,
  ParadiseLayout,
  ParadiseTitle,
  PillsWrapper,
  StatusWrapper,
  TopWrapper,
} from "src/components/Paradise/sharedStyles"
import { useParadiseDevicesFilter } from "src/components/Paradise/useParadiseDevicesFilter"
import { getDeviceHardwareType } from "src/data/devices/logic/deviceLogic"
import {
  useFetchParadiseDashboardDevices,
  useFetchParadiseDevicesCount,
} from "src/data/paradise/paradiseDevices/queries/paradiseDeviceQueries"
import {
  IParadiseDeviceListFilter,
  TParadiseDevice,
} from "src/data/paradise/paradiseDevices/types/paradiseDeviceQueryTypes"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { mColors } from "src/ui/colors"
import { DropdownMultiSelect } from "src/ui/DropdownSelect/DropdownMultiSelect"
import { useSortParam } from "src/ui/GridTable/useTableColumns/useSortParam"
import {
  TableColumn,
  useTableColumns,
} from "src/ui/GridTable/useTableColumns/useTableColumns"
import InfoIcon from "src/ui/icons/info.svg"
import { Titlebar } from "src/ui/Layout/Titlebar"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MBadge } from "src/ui/MBadge/MBadge"
import { MText } from "src/ui/MText"
import { MTooltip } from "src/ui/MTooltip/MTooltip"
import { spacing } from "src/ui/spacing"
import { WifiIcon } from "src/ui/WifiIcon/WifiIcon"
import { formatUtcDate } from "src/utils/l10n"

const tableColumns: TableColumn<TParadiseDevice>[] = [
  {
    label: "Device id",
    value: "device_id",
    disabled: true,
    enableSort: true,
    columnWidth: "min-content",
    disableClickPropagation: true,
    render: (data) => (
      <IdWrapper>
        <InternalLink
          to={Routes.ParadiseDevice.location(data.device_id).pathname}
        >
          {data.device_id}
        </InternalLink>
        {!data.active && (
          <MTooltip title="Inactive">
            <InfoIcon width={12} fill={mColors.systemErrorLight} />
          </MTooltip>
        )}
      </IdWrapper>
    ),
  },
  {
    label: "Device mac",
    value: "device_mac",
    disabled: true,
    enableSort: true,
    columnWidth: "min-content",
    render: (data) => <div>{data.device_mac}</div>,
  },
  {
    label: "Description",
    value: "description",
    columnWidth: 300,
    disabled: true,
    enableSort: true,
    render: (data) => <Ellipsis>{data.description}</Ellipsis>,
  },
  {
    label: "Owner",
    value: "owner",
    enableSort: true,
    columnWidth: "min-content",
    disableClickPropagation: true,
    render: (data) => (
      <InternalLink to={Routes.ParadiseUser.location(data.owner).pathname}>
        {data.owner}
      </InternalLink>
    ),
  },
  {
    label: "Tags",
    value: "tags",
    columnWidth: "min-content",
    render: (data) => (
      <div>
        <PillsWrapper $noWrap>
          {data.tags?.map((tag, i) => (
            <MBadge size="x-small" key={tag + i}>
              {tag}
            </MBadge>
          ))}
        </PillsWrapper>
      </div>
    ),
  },
  {
    label: "Last updated at",
    enableSort: true,
    value: "last_updated_at",
    columnWidth: "min-content",
    render: (data) => (
      <div>
        {data.last_heard_from_at
          ? formatUtcDate({ date: data.last_heard_from_at })
          : "-"}
      </div>
    ),
  },
  {
    label: "Status",
    enableSort: true,
    value: "status",
    columnWidth: "min-content",
    render: (data) => (
      <div>
        <OnlineStatus online={!data.offline} />
      </div>
    ),
  },
  {
    label: "Battery",
    enableSort: true,
    value: "battery",
    columnWidth: "min-content",
    render: (data) => (
      <StatusWrapper>
        <BatteryAndChargeStatusIcon
          percent={data.battery?.percent}
          offline={data.offline}
          chargeStatus={data.charge_status}
        />
        {data.battery?.voltage && (
          <MText variant="nano">{data.battery?.voltage.toFixed(2)}V</MText>
        )}
      </StatusWrapper>
    ),
  },
  {
    label: "Signal",
    value: "signal",
    columnWidth: "min-content",
    render: (data) => (
      <StatusWrapper>
        <WifiIcon quality={data.wlan?.signal_quality} offline={data.offline} />
        {data.wlan?.rssi && <MText variant="nano">{data.wlan?.rssi}</MText>}
      </StatusWrapper>
    ),
  },
  {
    label: "Firmware",
    enableSort: true,
    value: "firmware",
    columnWidth: "min-content",
    render: (data) => <div>{data.firmware.installed}</div>,
  },
  {
    label: "Hardware",
    enableSort: true,
    value: "hardware",
    columnWidth: "min-content",
    render: (data) => (
      <div>{getDeviceHardwareType(data.hardware_version).type}</div>
    ),
  },
]

const LIMIT = 50

export function ParadiseDevices() {
  const { navigate } = useRouter()

  const { filter, setFilter, offset, setOffset } = useParadiseDevicesFilter()

  const { sortValue, setSortValue } = useSortParam()

  // If the user has filtered we want to show inactive devices aswell, otherwise just active devices is fetched
  const hasFiltered = Object.values(filter).some((f) => !!f)

  const fetchedDevices = useFetchParadiseDashboardDevices({
    filter: {
      limit: LIMIT,
      offset,
      device_id: filter.devices_id || undefined,
      description: filter.devices_description || undefined,
      device_mac: filter.devices_mac || undefined,
      active: !hasFiltered || undefined,
      installed_firmware: filter.devices_firmware || undefined,
      hardware_version: filter.devices_hardwareVersion ?? undefined,
      or_tags: filter.devices_tag ?? undefined,
      owner_id: filter.devices_owner || undefined,
      offline: filter.devices_offline ?? undefined,
      sort:
        (sortValue?.id as IParadiseDeviceListFilter["sort"]) || "device_mac",
      sort_by: sortValue?.order || "asc",
    },
    options: {
      keepPreviousData: true,
    },
  })

  const fetchDevicesCount = useFetchParadiseDevicesCount()

  const {
    headerElements,
    rows,
    interactiveColumns,
    setColumnVisibility,
    interactiveVisibleColumns,
    templateColumns,
  } = useTableColumns<TParadiseDevice>({
    columns: tableColumns,
    data: fetchedDevices.data?.devices,
    sort: sortValue,
    onSortChange: (newSort) => {
      setSortValue(newSort)
    },
    options: { localStorageKey: "minut.paradise.devices.table" },
  })

  return (
    <ParadiseLayout>
      <Titlebar
        bottomMargin={spacing.S}
        title={
          <ParadiseTitle>
            Devices
            {fetchDevicesCount.isSuccess && (
              <>
                <MBadge color="good">
                  Online: {fetchDevicesCount.data?.online}
                </MBadge>{" "}
                <MBadge color="error">
                  Offline: {fetchDevicesCount.data?.offline}
                </MBadge>
              </>
            )}
          </ParadiseTitle>
        }
      />

      <TopWrapper>
        <ParadiseDevicesFilters
          filter={filter}
          setFilter={setFilter}
          showFirmwareFilter
          showMacAddressFilter
          showTagFilter
        />
        <DropdownMultiSelect
          label="Columns"
          selectedValues={interactiveVisibleColumns.map((c) => c.value)}
          options={interactiveColumns}
          onChange={({ checked, option }) => {
            setColumnVisibility(option.value, !checked)
          }}
        />
      </TopWrapper>
      <ParadiseTable
        header={headerElements}
        rows={rows ?? []}
        templateColumns={templateColumns}
        onRowClick={(index) => {
          const deviceId = fetchedDevices.data?.devices?.[index]?.device_id

          if (!deviceId) {
            throw new Error("Device id is missing when clicking on a row")
          }

          navigate(Routes.ParadiseDevice.location(deviceId))
        }}
        error={{
          hasError: fetchedDevices.isError,
          title: fetchedDevices.error?.message,
        }}
      />
      <ParadisePager
        limit={LIMIT}
        offset={offset}
        setOffset={setOffset}
        totalCount={fetchedDevices.data?.total_count}
      />
    </ParadiseLayout>
  )
}
